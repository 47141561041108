<template>
  <div class="contact-list-item">
    <div class="title">{{title}}</div>
    <div class="contView">
      <template v-for="(item,index) in conts">
        <div class="contItem" :key="index">
          <i class="icon" :style="{backgroundImage:`url(${getImg(item.imgName)})`}"></i>
          <a v-if="item.type=='phone'" href="tel:`${item.txt}`" class="cont">{{item.txt}}</a>
          <a v-else-if="item.type=='email'" href="mailto:`${item.txt}`" class="cont">{{item.txt}}</a>
          <span v-else class="cont">{{item.txt}}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .contact-list-item {
    text-align: left;
    margin: 4vw;
    .title {
      font-size: 3.5vw;
      color: #000;
      font-weight: bold;
      margin-bottom: 1.5vw;
    }
    // 联系方式内容
    .contView {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .contItem {
        display: flex;
        align-items: center;
        margin-bottom: 1.5vw;
        .icon {
          width:6vw;
          height:6vw;
          background-size: cover;
          margin-right: 1vw;
        }
        .cont {
          font-size: 4vw;
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
</style>
<script>
export default {
  name: "contactitem",
  props: ['title','conts'],
  methods:{
    getImg(imgName){
      try {
        return require(`@/assets/img/${imgName}`);
      }catch (e) {
        return require(`@/assets/img/contact_us_19.png`);
      }
    }
  }
}
</script>