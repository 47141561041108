<template>
  <div class="contact-us">
    <myHeader title="联系我们" sTitle="Contact Us" />
    <img class="banner" src="~@/assets/img/banner.png" alt="">
    <img class="titpng" src="~@/assets/img/tit-tel.png" alt="">
    <contactItem :title="data1.title" :conts="data1.conts" />
    <contactItem :title="data2.title" :conts="data2.conts" />
    <img class="titpng" src="~@/assets/img/tit-address.png" alt="">
    <contactItem :title="data3.title" :conts="data3.conts" />
    <contactItem :title="data4.title" :conts="data4.conts" />
  </div>
</template>
<style lang="scss" scoped>
  .contact-us {
    .banner {
      width:100%;
      height: auto;
    }
    .titpng {
      margin-top: 5vw;
      width: 40vw;
    }
  }
</style>
<script>
import myHeader from "@/components/MyHeader.vue"
import ContactItem from "@/components/ContactItem.vue"
export default {
  name:'contactUs',
  components:{
    myHeader,ContactItem
  },
  data() {
    return {
      data1:{
        title:'市场部:',
        conts:[
          {imgName:'contact_us_17.png',txt:'0755 - 8635 0159',type:'phone'},
          {imgName:'contact_us_19.png',txt:'19865767675',type:'phone'},
        ]
      },
      data2:{
        title:'资源部:',
        conts:[
          {imgName:'contact_us_17.png',txt:'0755 - 8635 0155',type:'phone'}
        ]
      },
      data3:{
        title:'邮箱:',
        conts:[
          {imgName:'contact_us_27.png',txt:'sales@beidoucom.com',type:'email'}
        ]
      },
      data4:{
        title:'前台:',
        conts:[
          {imgName:'contact_us_29.png',txt:'深圳市南山区科苑南路南京大学产学研基地 B栋7楼',type:'text'}
        ]
      },
    }
  },
}
</script>